<template>
  <v-main>
    <v-container fruid class="bl_works">
      <h3 class="text-h5 my-4">WORKS</h3>
      <v-row justify="start" align="stretch">
        <v-col v-for="(article, index) in itemCount" :key="article.date + '_' + index"
            cols="12"
            sm="12"
            md="4"
            min-width="300">
          <v-card class="ma-0 pt-8 pb-0 px-8"  height="100%">
            <v-card-text class="text--primary pa-0">
              <!-- 画像はここに受け取る -->
              <div class="summary" v-html="article.html"></div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-title class="px-0 py-2 text-h7">{{ article.title }}</v-card-title>
                <div class="summary" v-html="article.text"></div>
                <p  class="text-caption text-right mt-4 mb-8">{{ new Date(article.date).toLocaleDateString() }}</p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <!-- 以下はデータベース管理なし -->
        <v-col
            cols="12"
            sm="12"
            md="4"
            min-width="300">
          <v-card class="ma-0 pa-8"  height="100%">
            <v-card-text class="text--primary pa-0">
              <div class="summary">
                <img src="https://interemit.com/img/direct_img/DSC_0130_re.png" width="250" height="250"/>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-title class="px-0 py-2 text-h7"></v-card-title>
                <div class="summary">大室城の案内看板</div>
                <p  class="text-caption text-right mt-4 mb-0">かつて霞ヶ浦沿岸の見晴らしの良い台地に存在した大室城についての案内看板。城跡専門家に知見を伺いイメージ図を作成しました。※現地は住宅街ですので訪問の際はご配慮をお願いします。
                <a href="https://interemit.com/works/oomurojo" target="_blank" rel="noopener noreferrer">以下のページでイラストの作成経過を動画でご覧いただけます。<small>（約15MB）</small></a>
                </p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="4"
            min-width="300">
          <v-card class="ma-0 pa-8"  height="100%">
            <v-card-text class="text--primary pa-0">
              <div class="summary">
                <img src="https://interemit.com/img/direct_img/nenga2018_007_ol_sq.png" width="250" height="250">
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-title class="px-0 py-2 text-h7"></v-card-title>
                <div class="summary">年賀状イラスト（2009年〜）</div>
                <p  class="text-caption text-right mt-4 mb-0">会社員時代から販売・印刷用年賀状の図柄制作に携わり、2009年の寅年から2022年で干支をひとまわりしました。ストックイラストの方でも好評販売中。</p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="4"
            min-width="300">
          <v-card class="ma-0 pa-8"  height="100%">
            <v-card-text class="text--primary pa-0">
              <div class="summary">
                <img src="https://interemit.com/img/direct_img/2021_01.png" />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-title class="px-0 py-2 text-h7"></v-card-title>
                <div class="summary">冊子まわりみち</div>
                <p  class="text-caption text-right mt-4 mb-0">地域の芸術と音楽にフォーカスした冊子です。ディレクションと一部ページデザイン、広告簡易入稿システムを担当しました。</p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="4"
            min-width="300">
          <v-card class="ma-0 pa-8"  height="100%">
            <v-card-text class="text--primary pa-0">
              <div class="summary">
                <img src="/img/direct_img/bookcover_both_blue.png" />
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-title class="px-0 py-2 text-h7"></v-card-title>
                <div class="summary">友朋堂書店イベント限定ブックカバーイラスト</div>
                <p  class="text-caption text-right mt-4 mb-0"></p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="4"
            min-width="300">
          <v-card class="ma-0 pa-8"  height="100%">
            <v-card-text class="text--primary pa-0">
              <div class="summary">
                <img src="/img/direct_img/2018_jisseki2.png" />
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-title class="px-0 py-2 text-h7"></v-card-title>
                <div class="summary">まつりつくば スポーツパークポスター デザイン</div>
                <p  class="text-caption text-right mt-4 mb-0"></p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="4"
            min-width="300">
          <v-card class="ma-0 pa-8"  height="100%">
            <v-card-text class="text--primary pa-0">
              <div class="summary">
                <img src="/img/direct_img/2018_jisseki3.png" />
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-title class="px-0 py-2 text-h7"></v-card-title>
                <div class="summary">つくばエクスプレス つくば道スタンプウォーク パンフレットデザイン<br />イベント運営サポート</div>
                <p  class="text-caption text-right mt-4 mb-0"></p>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="start" align="stretch" class="mb-16">
        <span class="px-2">公開案件以外につきましては個別にお問い合わせください。業務用アプリケーションの画面設計、webサイト、ロゴ、チラシ等手がけております。</span>
      </v-row>
    </v-container>

  </v-main>
</template>

<script>
import axios from "axios";
import sanitizeHtml from "sanitize-html";

  export default {
    name: 'works-detail',
    components: {

    },
    methods:{
    },
    data(){
      return{
        articles: [],
        show: false,
      }
    },
    computed: {
        itemCount() {
        return this.articles.slice(0,3)
        },
        sanitizedBody() {
        // imgタグのみ使えるようにする
        return sanitizeHtml(this.article.bodytext, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"])
        });
        }
    },

    async mounted() {
        // 記事を取得する
        const response = await axios.get(
        "https://interemitcms.microcms.io/api/v1/works",
        {
            headers: { "X-API-KEY": '53b77b5c-566a-4d9e-9c01-deb5cf1a83ac' }
        }
        );
        this.articles = response.data.contents;
    }
  }
</script>

<style scoped>
.bl_works >>> img{
  width: 100%;
  max-width: 300px;
  height: auto;
  max-height: 300px;
  margin-bottom: 16px;
  object-fit: cover;
}
.bl_works .summary{
  overflow: hidden;
}
</style>
